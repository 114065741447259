import { Box, Center, Image } from "@chakra-ui/react"
import React from "react"
import schoolsLogo from "../../images/Schoolslogo.png"
import { Trans } from "gatsby-plugin-react-i18next"

const TrustedBy = () => {
  return (
    <Box
      mx={{ xl: "8rem", lg: "4rem", base: "1rem" }}
      mb={{ xl: "24", lg: "16" }}
    >
      <Box
        fontSize={{ "2xl": 40, xl: 35, lg: 30, base: 25 }}
        textAlign={"center"}
        mb="2"
        fontWeight={{ xl: 700, base: 600 }}
        mx={{ xl: "15rem", lg: "9rem", base: "0.5rem" }}
      >
        <Trans>Trusted by 200+ Schools Nationwide and Internationally</Trans>
      </Box>
      <Box
        mb="3"
        fontSize={{ xl: 20, base: 17 }}
        fontWeight={500}
        textAlign={"center"}
        mx={{ "2xl": "16rem", xl: "8rem", lg: "4rem", base: "1rem" }}
      >
        <Trans>
          Around the globe, individuals schools enjoy the benefit of autonomy in
          selecting the best technology partners to meet their school's unique
          needs.
        </Trans>
      </Box>
      <Box mb="5">
        <Center>
          <Image src={schoolsLogo} alt="logos" w={{ xl: "70%", base: "95%" }} />
        </Center>
      </Box>
    </Box>
  )
}

export default TrustedBy
